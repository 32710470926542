import router from "./router";
import store from "./store";
import { GetUser } from "@/api";

const loginPath = "/login";
const mainPath = "/";

const noNeedPermitPaths = [
    "/"
];
router.beforeEach((to, form, next) => {
    if (to.query.userid && to.query.userid !== "") {
        store.dispatch("login", {
            user_id: to.query.userid
        });
        GetUser({
                Id: store.state.user.userId,
                userToken: {
                    userId: store.state.user.userId,
                },
            })
            .then((res) => {
                console.log(res.data)
                if (res.code === 0) {
                    store.dispatch("setUser", {
                        real_name: res.data.list[0].realName,
                        dept: res.data.list[0].dept
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (noNeedPermitPaths.includes(to.path)) {
        next();
        return;
    }
    if (to.path === loginPath) {
        if (store.state.user.state.isLogined) {
            next(mainPath);
            return;
        }
        next();
        return;
    }

    if (!store.state.user.state.isLogined) {
        // next(loginPath);
        next();
    } else {
        next();
    }
});