export default class ComponentStore {
    static attachTo(vue) {
        vue.component('buttonGroup', () =>
            import("./mobile/buttonGroup.vue"));
        vue.component('inputItem', () =>
            import("./mobile/inputItem.vue"));
        vue.component('searchBox', () =>
            import("./mobile/searchBox.vue"));
        vue.component('searchDrawer', () =>
            import("./mobile/searchDrawer.vue"));
        vue.component('itemTotal', () =>
            import("./mobile/itemTotal.vue"));
        vue.component('itemBox', () =>
            import("./mobile/itemBox.vue"));
        vue.component('optionDrawer', () =>
            import("./mobile/optionDrawer.vue"));
        vue.component('orderScroll', () =>
            import("./mobile/orderScroll.vue"));
        vue.component('inquiryFilter', () =>
            import("./mobile/inquiryFilter.vue"));
        vue.component('saleFilter', () =>
            import("./mobile/saleFilter.vue"));
        vue.component('purchaseFilter', () =>
            import("./mobile/purchaseFilter.vue"));
        vue.component('projectFilter', () =>
            import("./mobile/projectFilter.vue"));
        vue.component('uploadBox', () =>
            import("./mobile/uploadBox.vue"));
        vue.component('personSelect', () =>
            import("./mobile/personSelect.vue"));
        vue.component('comment', () =>
            import("./mobile/comment.vue"));
        vue.component('taskbox', () =>
            import("./mobile/taskbox.vue"));
        vue.component('tablebox', () =>
            import("./web/tablebox.vue"));

    }
}
