import JSEncrypt from 'jsencrypt'

/* 不对称加密 */
export const encrypt = function(src) {
    var cryptFirst = new JSEncrypt();
    cryptFirst.setPublicKey(`MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDQQ9RQN6lMrzAmjBWnKkt0A9pk
  Xef+PxWaT9DqS53mOL3JbYMr1yHQK3fz72ebkHdROk8FT3aeYkSLGht0JxNWBj6n
  QfRSF1aBBnMqzdDz7F9tPoOdIqKOKVf/+3eBPfoQYwLw6BkOn1A7VC1qYE+B35uL
  9fVRaxRJW+mtJuTmTwIDAQAB`);
    return cryptFirst.encrypt(src);
};