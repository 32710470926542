import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            reducer(val) {
                return {
                    user: val.user,
                };
            },
        }),
    ],
    state: {
        list: {},
        id: "",
        user: {
            account: "",
            remember: false,
            password: "",
            realName: "",
            userId: "",
            jwt: "",
            state: {
                isLogined: false
            },
        },
    },
    getters: {
        getlist: (state) => {
            return state.list
        },

    },

    mutations: {
        setlist: (state, date) => {
            state.list = date
        },

        login(state, payload) {
            state.user.state.isLogined = true;
            state.user.account = payload.username;
            state.user.realName = payload.real_name;
            state.user.userId = payload.user_id;
            state.user.remember = payload.remember;
            state.user.password = payload.password;
            state.user.jwt = payload.jwt;
        },
        leave(state) {
            state.user.realName = "";
            state.user.userId = "";
            state.user.userToken = "";
            state.user.state.isLogined = false;
        },
        setUser(state, payload) {
            state.user.realName = payload.real_name;
            state.user.dept = payload.dept;
        },
    },
    actions: {
        login({
            commit
        }, payload) {
            commit("login", payload);
        },
        leave({
            commit
        }) {
            commit("leave");
        },
        setUser({
            commit
        }, payload) {
            commit("setUser", payload);
        },
    },
    modules: {},
});