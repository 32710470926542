import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import componentStore from "./components";
import echarts from 'echarts';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import scroll from 'vue-seamless-scroll'

import Vant from 'vant';
import 'vant/lib/index.css';

import {
    // eslint-disable-next-line no-unused-vars
    Style,
    Button,
    Loading,
    Scroll,
    Dialog,
    Toast,
    DatePicker,
    TimePicker,
    TabBar,
    TabPanels,
    Swipe,
    Select,
    Input,
    Upload,
    Slide,
    Drawer,
    ScrollNav,
    SegmentPicker,
    Checkbox,
    // eslint-disable-next-line no-unused-vars
    createAPI,
} from "cube-ui"; // 按需引入，参考文档 https://didi.github.io/cube-ui/#/zh-CN/docs/introduction

//下面两个 import 放在所有 import 之后
import "./permission";

Vue.config.productionTip = false;

Vue.use(Slide);
Vue.use(Style);
Vue.use(Button);
Vue.use(Loading);
Vue.use(Scroll);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(TabBar);
Vue.use(TabPanels);
Vue.use(Swipe);
Vue.use(Select);
Vue.use(Input);
Vue.use(Upload);
Vue.use(Drawer);
Vue.use(ScrollNav);
Vue.use(SegmentPicker);
Vue.use(Checkbox);

Vue.use(ViewUI);
Vue.use(scroll);

Vue.use(Vant);

componentStore.attachTo(Vue);
Vue.prototype.$echarts = echarts;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
