import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/test",
    name: "test",
    meta: {
        title: '组件测试'
    },
    component: () =>
        import("../components/test.vue"),
}, {
    path: "/mobile/office/taskulous",
    name: "mTaskulous",
    meta: {
        title: '待办事务'
    },
    component: () =>
        import("../views/mobile/office/taskulous.vue"),
}, {
    path: "/mobile/erp/inquirylist",
    name: "mInquiryList",
    meta: {
        title: '询价订单'
    },
    component: () =>
        import("../views/mobile/erp/inquiryList.vue"),
},
{
    path: "/mobile/erp/inquirydetail",
    name: "mInquiryDetail",
    meta: {
        title: '询价订单'
    },
    component: () =>
        import("../views/mobile/erp/inquiryDetail.vue"),
}, {
    path: "/mobile/erp/salelist",
    name: "mSaleList",
    meta: {
        title: '订单列表'
    },
    component: () =>
        import("../views/mobile/erp/saleList.vue"),
},
{
    path: "/mobile/erp/saledetail",
    name: "mSaleDetail",
    meta: {
        title: '订单详情'
    },
    component: () =>
        import("../views/mobile/erp/saleDetail.vue"),
}, {
    path: "/mobile/erp/purchaselist",
    name: "mPurchaseList",
    meta: {
        title: '采购订单'
    },
    component: () =>
        import("../views/mobile/erp/purchaseList.vue"),
},
{
    path: "/mobile/erp/purchasedetail",
    name: "mPurchaseDetail",
    meta: {
        title: '采购订单'
    },
    component: () =>
        import("../views/mobile/erp/purchaseDetail.vue"),
},
{
    path: "/mobile/crm/chanceList",
    name: "mChanceList",
    meta: {
        title: '商机列表'
    },
    component: () =>
        import("../views/mobile/crm/chanceList.vue"),
},
{
    path: "/mobile/crm/chanceDetail",
    name: "mChanceDetail",
    meta: {
        title: '商机详情'
    },
    component: () =>
        import("../views/mobile/crm/chanceDetail.vue"),
},
{
    path: "/mobile/crm/projectList",
    name: "mProjectList",
    meta: {
        title: '合同列表'
    },
    component: () =>
        import("../views/mobile/crm/projectList.vue"),
},
{
    path: "/mobile/crm/projectDetail",
    name: "mProjectDetail",
    meta: {
        title: '合同详情'
    },
    component: () =>
        import("../views/mobile/crm/projectDetail.vue"),
},
{
    path: "/mobile/crm/projectInvoiceList",
    name: "mProjectInvoiceList",
    meta: {
        title: '合同开票',
    },
    component: () =>
        import("../views/mobile/crm/projectInvoiceList.vue"),
},
{
    path: "/mobile/crm/projectInvoiceDetail",
    name: "mProjectInvoiceDetail",
    meta: {
        title: '合同开票',
    },
    component: () =>
        import("../views/mobile/crm/projectInvoiceDetail.vue"),
}, {
    path: "/mobile/office/costList",
    name: "mCostList",
    meta: {
        title: '费用报销'
    },
    component: () =>
        import("../views/mobile/office/costList.vue"),
},
{
    path: "/mobile/office/costdetail",
    name: "mCostDetail",
    meta: {
        title: '费用详情'
    },
    component: () =>
        import("../views/mobile/office/costDetail.vue"),
}, {
    path: "/mobile/office/visitplanList",
    name: "mVisitPlanList",
    meta: {
        title: '工作计划'
    },
    component: () =>
        import("../views/mobile/office/visitPlanList.vue"),
},
{
    path: "/mobile/office/visitplandetail",
    name: "mVisitPlanDetail",
    meta: {
        title: '工作计划'
    },
    component: () =>
        import("../views/mobile/office/visitPlanDetail.vue"),
},
{
    path: "/mobile/office/visitplanitem",
    name: "mVisitPlanItem",
    meta: {
        title: '计划详情'
    },
    component: () =>
        import("../views/mobile/office/visitPlanItem.vue"),
},
{
    path: "/mobile/office/visitplanreview",
    name: "mVisitPlanReview",
    meta: {
        title: '计划总结'
    },
    component: () =>
        import("../views/mobile/office/visitPlanReview.vue"),
}, {
    path: "/mobile/office/travelList",
    name: "mTravelList",
    meta: {
        title: '出差申请'
    },
    component: () =>
        import("../views/mobile/office/travelList.vue"),
},
{
    path: "/mobile/office/traveldetail",
    name: "mTravelDetail",
    meta: {
        title: '出差申请'
    },
    component: () =>
        import("../views/mobile/office/travelDetail.vue"),
}, {
    path: "/mobile/office/entertainList",
    name: "mEntertainList",
    meta: {
        title: '业务交流'
    },
    component: () =>
        import("../views/mobile/office/entertainList.vue"),
},
{
    path: "/mobile/office/entertaindetail",
    name: "mEntertainDetail",
    meta: {
        title: '业务交流'
    },
    component: () =>
        import("../views/mobile/office/entertainDetail.vue"),
}, {
    path: "/mobile/office/taskList",
    name: "mTaskList",
    meta: {
        title: '任务列表'
    },
    component: () =>
        import("../views/mobile/office/taskList.vue"),
},
{
    path: "/mobile/office/taskdetail",
    name: "mTaskDetail",
    meta: {
        title: '任务详情'
    },
    component: () =>
        import("../views/mobile/office/taskDetail.vue"),
}, {
    path: "/mobile/office/newslist",
    name: "mNewsList",
    meta: {
        title: '新闻列表'
    },
    component: () =>
        import("../views/mobile/office/newsList.vue"),
},
{
    path: "/mobile/office/newsdetail",
    name: "mNewsDetail",
    meta: {
        title: '新闻详情'
    },
    component: () =>
        import("../views/mobile/office/newsDetail.vue"),
}, {
    path: "/mobile/erp/invoicelist",
    name: "mInvoiceList",
    meta: {
        title: '销售开票'
    },
    component: () =>
        import("../views/mobile/erp/invoiceList.vue"),
},
{
    path: "/mobile/erp/invoicedetail",
    name: "mInvoiceDetail",
    meta: {
        title: '销售开票'
    },
    component: () =>
        import("../views/mobile/erp/invoiceDetail.vue"),
}, {
    path: "/mobile/office/timecardLogList",
    name: "mTimecardLogList",
    meta: {
        title: '打卡审批'
    },
    component: () =>
        import("../views/mobile/office/timecardLogList.vue"),
},
{
    path: "/mobile/office/timecardLogDetail",
    name: "mTimecardLogDetail",
    meta: {
        title: '打卡审批'
    },
    component: () =>
        import("../views/mobile/office/timecardLogDetail.vue"),
}, {
    path: "/mobile/office/vacationList",
    name: "mVacationList",
    meta: {
        title: '请假列表'
    },
    component: () =>
        import("../views/mobile/office/vacationList.vue"),
},
{
    path: "/mobile/office/vacationDetail",
    name: "mVacationDetail",
    meta: {
        title: '请假详情'
    },
    component: () =>
        import("../views/mobile/office/vacationDetail.vue"),
}, {
    path: "/mobile/office/otherMoneyList",
    name: "mOtherMoneyList",
    meta: {
        title: '其他收付款列表'
    },
    component: () =>
        import("../views/mobile/office/otherMoneyList.vue"),
},
{
    path: "/mobile/office/otherMoneyDetail",
    name: "mOtherMoneyDetail",
    meta: {
        title: '其他收付款详情'
    },
    component: () =>
        import("../views/mobile/office/otherMoneyDetail.vue"),
}, {
    path: "/mobile/office/carCostList",
    name: "mCarCostList",
    meta: {
        title: '车辆费用列表'
    },
    component: () =>
        import("../views/mobile/office/carCostList.vue"),
},
{
    path: "/mobile/office/carCostDetail",
    name: "mCarCostDetail",
    meta: {
        title: '车辆费用详情'
    },
    component: () =>
        import("../views/mobile/office/carCostDetail.vue"),
}, {
    path: "/mobile/office/carUseList",
    name: "mCarUseList",
    meta: {
        title: '车辆使用列表'
    },
    component: () =>
        import("../views/mobile/office/carUseList.vue"),
},
{
    path: "/mobile/office/carUseDetail",
    name: "mCarUseDetail",
    meta: {
        title: '车辆使用详情'
    },
    component: () =>
        import("../views/mobile/office/carUseDetail.vue"),
},
{
    path: "/board/salerpk",
    name: "board_salerpk",
    meta: {
        title: '业绩PK榜'
    },
    component: () =>
        import("../views/web/board/salerpk.vue"),
},
{
    path: "/board/person",
    name: "board_person",
    meta: {
        title: '个人看板'
    },
    component: () =>
        import("../views/web/board/person.vue"),
},
{
    path: "/board/develop",
    name: "board_develop",
    meta: {
        title: '开发看板'
    },
    component: () =>
        import("../views/web/board/develop.vue"),
}, {
    path: "/mobile/office/accountApplyList",
    name: "mAccountApplyList",
    meta: {
        title: '帐号申请'
    },
    component: () =>
        import("../views/mobile/office/accountApplyList.vue"),
},
{
    path: "/mobile/office/accountApplyDetail",
    name: "mAccountApplyDetail",
    meta: {
        title: '帐号申请'
    },
    component: () =>
        import("../views/mobile/office/accountApplyDetail.vue"),
}, {
    path: "/mobile/crm/projectTrialList",
    name: "mProjectTrialList",
    meta: {
        title: '帐号申请'
    },
    component: () =>
        import("../views/mobile/crm/projectTrialList.vue"),
},
{
    path: "/mobile/crm/projectTrialDetail",
    name: "mProjectTrialDetail",
    meta: {
        title: '帐号申请'
    },
    component: () =>
        import("../views/mobile/crm/projectTrialDetail.vue"),
}, {
    path: "/mobile/erp/stockDemandList",
    name: "mStockDemandList",
    meta: {
        title: '备货需求'
    },
    component: () =>
        import("../views/mobile/erp/stockDemandList.vue"),
},
{
    path: "/mobile/erp/stockDemanddetail",
    name: "mStockDemandDetail",
    meta: {
        title: '备货需求'
    },
    component: () =>
        import("../views/mobile/erp/stockDemandDetail.vue"),
},
{
    path: "/mobile/usercentre/mycard",
    name: "mycard",
    meta: {
        title: '我的名片'
    },
    component: () =>
        import("../views/mobile/usercentre/mycard.vue"),
},
{
    path: "/mobile/usercentre/mytask",
    name: "mytask",
    meta: {
        title: '我的任务'
    },
    component: () =>
        import("../views/mobile/usercentre/mytask.vue"),
},
{
    path: "/mobile/usercentre/myperformance",
    name: "myperformance",
    meta: {
        title: '我的业绩'
    },
    component: () =>
        import("../views/mobile/usercentre/myperformance.vue"),
},
{
    path: "/mobile/contract/user",
    name: "contractuser",
    meta: {
        title: '合同统计'
    },
    component: () =>
        import("../views/mobile/contract/user.vue"),
},
{
    path: "/project/develop",
    name: "projectdevelop",
    meta: {
        title: '合同看板'
    },
    component: () =>
        import("../views/web/project/develop.vue"),
},
{
    path: "/project/detail",
    name: "projectdetail",
    meta: {
        title: '合同看板'
    },
    component: () =>
        import("../views/web/project/detail.vue"),
},
{
    path: "/calendar",
    name: "calendar",
    meta: {
        title: '测试'
    },
    component: () =>
        import("../components/mobile/calendar.vue"),
},
{
    path: "/mobile/office/visitplanboard",
    name: "mVisitPlanBoard",
    meta: {
        title: '计划看板'
    },
    component: () =>
        import("../views/mobile/office/visitPlanBoard.vue"),
}, 
{
    path: "/mobile/office/remindlist",
    name: "mRemindList",
    meta: {
        title: '提醒列表'
    },
    component: () =>
        import("../views/mobile/office/remindList.vue"),
}, 
{
    path: "/mobile/office/remindDetail",
    name: "mRemindDetail",
    meta: {
        title: '提醒详情'
    },
    component: () =>
        import("../views/mobile/office/remindDetail.vue"),
},
{
    path: "/mobile/crm/projectSummaryList",
    name: "mProjectSummaryList",
    meta: {
        title: '总结列表'
    },
    component: () =>
        import("../views/mobile/crm/projectSummaryList.vue"),
},
{
    path: "/mobile/crm/projectSummaryDetail",
    name: "mProjectSummaryDetail",
    meta: {
        title: '总结详情'
    },
    component: () =>
        import("../views/mobile/crm/projectSummaryDetail.vue"),
}, 
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;